<template>
	<v-footer color="grey darken-4" padless>
		<v-row justify="center" no-gutters>
			<v-btn v-for="(site, index) in sites" :key="index" :href="site.route" color="white" text class="my-2">
				{{ site.name }}
			</v-btn>
			<v-col class="grey darken-3 py-4 text-center white--text" cols="12">
				{{ new Date().getFullYear() }} — <strong></strong>
			</v-col>
		</v-row>
	</v-footer>
</template>

<script>
export default {
	data() {
		return {
			mobile: false,
			sidenav: false,
			sites: [
				{
					"route": "#home",
					"name": "Home",
					"icon": "mdi-file-download-outline"
				},
				{
					"route": "#servicios",
					"name": "Servicios",
					"icon": "mdi-archive"
				},
				{
					"route": "#nosotros",
					"name": "Nosotros",
					"icon": "mdi-checkbox-multiple-marked"
				},
				{
					"route": "#clientes",
					"name": "Clientes",
					"icon": "mdi-magnify-expand"
				},
				{
					"route": "#contacto",
					"name": "Contacto",
					"icon": "mdi-contacts"
				},
			]
		}
	},
	created: function () {
		var wh = window.screen.availWidth;
		if(wh < 600){
			this.mobile = true;
		}
	},
}
</script>
