<template>
	<div>
		<header id="home" class="fh header">
			<v-row class="fh bbg">
				<v-col class="hmenu" cols="12" align="right" v-if="!this.mobile">
					<span v-for="(site, index) in sites" :key="index">
						<v-btn v-if="site.name!='Home'" :href="site.route" color="white" x-large text>
						{{ site.name }}
						</v-btn>
					</span>
				</v-col>
				<v-col class="hmenu" cols="12" align="right" v-else></v-col>
				<v-col class="white--text" cols="12" sm="6" align="center" :order="(this.mobile)? 1 : 0">
					<h1 class="lema text-md-h1 text-sm-h2 text-xs-h3 mb-6">Grupo Gavira</h1>
					<p class="lema text-md-h4 text-sm-body-1">Profesionales en Seguridad Privada</p>
					<span class="lema">
						<p class="text-md-h5 text-sm-body-1">Configura la cadena de seguridad<br /> que cada cliente y necesita ...</p>
						<v-btn class="" x-large color="primary p-4" href="#servicios">
							Ver más
						</v-btn>
					</span>
				</v-col>
				<v-col :class="(this.mobile)? 'mt-16' : ''" cols="12" sm="6" align="center" :order="(this.mobile)? 0 : 1">
					<img id="logo" width="35%" src="/imgs/logo.webp" />
				</v-col>
			</v-row>
		</header>

		<section id="servicios">
			<v-container elevation="1">
				<h1 class="cardserv text-md-h2 text-sm-h3 mb-6">Servicios</h1>
				<div class="mx-auto mb-15" :style="(this.mobile)? 'width:90%': 'width:60%'" align="center">
					<p class="cardserv text-md-h5">Nuestro valor y diferenciación es el concimiento del negocio del cliente, la experiencia en la coordinación de amplios dispositivos de seguridad y en la formación de programas específicos.</p>
				</div>
				<v-row id="cardserv">
					<v-col cols="12" sm="4">
						<v-card class="cardserv mx-4" outlined>
							<v-img class="orange--text align-end" height="150px" lazy-src="https://picsum.photos/id/11/10/6" src="https://picsum.photos/id/0/225/150">
							</v-img>

							<v-card-title class="text-md-h3 text-sm-h4">
								Generales
							</v-card-title>

							<v-card-text class="text-md-body-1 text-sm-body-2">
								<p>Soluciones integrales en inmuebles e industrias, centros comerciales, etc.</p>
							</v-card-text>

							<v-card-actions>

							</v-card-actions>
						</v-card>
					</v-col>
					<v-col cols="12" sm="4">
						<v-card class="cardserv mx-4" outlined>
							<v-img class="white--text align-end" height="150px" lazy-src="https://picsum.photos/id/11/10/6" src="https://picsum.photos/id/1031/266/150">
							</v-img>

							<v-card-title class="text-md-h3 text-sm-h4">
								Consultoría
							</v-card-title>

							<v-card-text>
								<p class="text-md-body-1 text-sm-body-2">Dirigido a empresas de seguridad.</p>
							</v-card-text>

							<v-card-actions>

							</v-card-actions>
						</v-card>
					</v-col>
					<v-col cols="12" sm="4">
						<v-card class="cardserv mx-4" outlined>
							<v-img class="white--text align-end" height="150px" lazy-src="https://picsum.photos/id/11/10/6" src="https://picsum.photos/id/1048/225/150">
							</v-img>

							<v-card-title class="text-md-h3 text-sm-h4">
								Sociales
							</v-card-title>

							<v-card-text class="text-md-body-1 text-sm-body-2">
								<ul>
									<li>Becarios y prácticas</li>
									<li>Primeros auxilios</li>
									<li>Servicios de higiene</li>
									<li>Medidas de salud laboral</li>
									<li>Medidas organizativas</li>
								</ul>
							</v-card-text>

							<v-card-actions>

							</v-card-actions>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
		</section>

		<section class="dv dv1"></section>

		<section id="nosotros">
			<v-container>
				<h1 class="nos text-md-h2 text-sm-h3 mb-6">Nosotros</h1>
				<v-row class="my-6 pt-6" align="center">
					<v-col cols="12" sm="4">
						<v-row class="nos" align="center">
							<v-col :cols="(this.mobile)? '4' : '3'" align="center">
								<v-icon color="blue" :size="(this.mobile)? '90': '72'">mdi-lock</v-icon>
							</v-col>
							<v-col :cols="(this.mobile)? '8' : '9'" :align="(this.mobile)? 'left' : 'center'">
								<p class="my-auto text-md-h6 text-sm-body-1">15 Años en el sector de la Seguridad Privada</p>
							</v-col>
						</v-row>
					</v-col>
					<v-col cols="12" sm="4">
						<v-row class="nos" align="center">
							<v-col :cols="(this.mobile)? '4' : '3'" align="center">
								<v-icon color="blue" :size="(this.mobile)? '90': '72'">mdi-lightbulb</v-icon>
							</v-col>
							<v-col :cols="(this.mobile)? '8' : '9'" :align="(this.mobile)? 'left' : 'center'">
								<p class="my-auto text-md-h6 text-sm-body-1">Actuación personalizada y eficacia en la respuesta</p>
							</v-col>
						</v-row>
					</v-col>
					<v-col cols="12" sm="4">
						<v-row class="nos" align="center">
							<v-col :cols="(this.mobile)? '4' : '3'" align="center">
								<v-icon color="blue" :size="(this.mobile)? '90': '72'">mdi-currency-usd</v-icon>
							</v-col>
							<v-col :cols="(this.mobile)? '8' : '9'" :align="(this.mobile)? 'left' : 'center'">
								<p class="my-auto text-md-h6 text-sm-body-1">Solvencia financiera</p>
							</v-col>
						</v-row>
					</v-col>
					<v-col cols="12" sm="4">
						<v-row class="nos" align="center">
							<v-col :cols="(this.mobile)? '4' : '3'" align="center">
								<v-icon color="blue" :size="(this.mobile)? '90': '72'">mdi-handshake</v-icon>
							</v-col>
							<v-col :cols="(this.mobile)? '8' : '9'" :align="(this.mobile)? 'left' : 'center'">
								<p class="my-auto text-md-h6 text-sm-body-1">Vocación de excelencia en el servicio y compromiso con nuestros clientes</p>
							</v-col>
						</v-row>
					</v-col>
					<v-col cols="12" sm="4">
						<v-row class="nos" align="center">
							<v-col :cols="(this.mobile)? '4' : '3'" align="center">
								<v-icon color="blue" :size="(this.mobile)? '90': '72'">mdi-web</v-icon>
							</v-col>
							<v-col :cols="(this.mobile)? '8' : '9'" :align="(this.mobile)? 'left' : 'center'">
								<p class="my-auto text-md-h6 text-sm-body-1">Presencia nacional</p>
							</v-col>
						</v-row>
					</v-col>
					<v-col cols="12" sm="4">
						<v-row class="nos" align="center">
							<v-col :cols="(this.mobile)? '4' : '3'" align="center">
								<v-icon color="blue" :size="(this.mobile)? '90': '72'">mdi-chart-pie</v-icon>
							</v-col>
							<v-col :cols="(this.mobile)? '8' : '9'" :align="(this.mobile)? 'left' : 'center'">
								<p class="my-auto text-md-h6 text-sm-body-1">Presencia en todos los sectores del mercado español</p>
							</v-col>
						</v-row>
					</v-col>
					<v-col cols="12" sm="4">
						<v-row class="nos" align="center">
							<v-col :cols="(this.mobile)? '4' : '3'" align="center">
								<v-icon color="blue" :size="(this.mobile)? '90': '72'">mdi-file-certificate</v-icon>
							</v-col>
							<v-col :cols="(this.mobile)? '8' : '9'" :align="(this.mobile)? 'left' : 'center'">
								<p class="my-auto text-md-h6 text-sm-body-1">Profecionales altamante cualificados</p>
							</v-col>
						</v-row>
					</v-col>
					<v-col cols="12" sm="4">
						<v-row class="nos" align="center">
							<v-col :cols="(this.mobile)? '4' : '3'" align="center">
								<v-icon color="blue" :size="(this.mobile)? '90': '72'">mdi-clock-time-three</v-icon>
							</v-col>
							<v-col :cols="(this.mobile)? '8' : '9'" :align="(this.mobile)? 'left' : 'center'">
								<p class="my-auto text-md-h6 text-sm-body-1">Soluciones de seguridad integral en tiempo real</p>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-container>
		</section>

		<section id="clientes">
			<v-container>
				<h1 class="nos text-md-h2 text-sm-h3 mb-6">Clientes</h1>
				<v-card elevation="2" max-width="800" class="mx-auto">
					<template v-if="windowwidth > 800">
						<v-carousel cycle show-arrows-on-hover hide-delimiter-background delimiter-icon="mdi-minus" width="800" height="300">
							<v-carousel-item v-for="(item,i) in items" :key="i" :src="item.src" reverse-transition="fade-transition" transition="fade-transition"></v-carousel-item>
						</v-carousel>
					</template>
					<template v-else>
						<v-row class="my-6 pt-6" align="center">
							<v-col cols="12" sm="4" v-for="(item,i) in items" :key="i">
								<v-img :src="item.src"></v-img>
							</v-col>
						</v-row>
					</template>
				</v-card>
			</v-container>
		</section>

		<section class="dv dv2"></section>

		<section class="black">
			<v-container elevation="1">
				<div align="center">
					<video class="video" controls>
						<source src="/videos/presentacion.mp4" type="video/mp4">
						Su navegador no soporta los videos.
					</video>
				</div>
			</v-container>
		</section>

		<section id="contacto">
			<v-container elevation="1">
				<h1 class="cardserv text-md-h2 text-sm-h3 mb-6">Contacto</h1>
				<v-row>
					<v-col cols="12" md="5">
						<v-form>
							<v-row>
								<v-col cols="12" v-for="(campo, index) in campos" :key="index">
									<template v-if="campo.type == 'text'">
										<v-text-field color="orange" :label="campo.label" />
									</template>
								</v-col>

								<v-col cols="12">
										<v-textarea color="orange" label="Mensaje" />
								</v-col>

								<v-col cols="12" class="text-right">
									<v-btn color="orange" dark min-width="150">
										<v-icon left>mdi-email</v-icon>
										Enviar
									</v-btn>
								</v-col>
							</v-row>
						</v-form>
					</v-col>
					<v-col cols="12" md="7">
						<iframe class="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3754.1352380843023!2d-99.10900468501998!3d19.791862586681255!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d18bf478b96171%3A0x45f307eee6f38733!2sAv%20Insurgentes%202%2C%20San%20Marcos%2C%2055606%20Zumpango%20de%20Ocampo%2C%20M%C3%A9x.!5e0!3m2!1ses-419!2smx!4v1644012160735!5m2!1ses-419!2smx" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
					</v-col>
				</v-row>
			</v-container>
		</section>
	</div>
</template>

<script>
	import { gsap } from "gsap";
	import { ScrollTrigger } from "gsap/ScrollTrigger";

	gsap.registerPlugin(ScrollTrigger);

	export default {
		name: 'Home',
		data: () => ({
			mobile: false,
			windowwidth: 0,
			sites: [
				{
					"route": "#home",
					"name": "Home",
					"icon": "mdi-file-download-outline"
				},
				{
					"route": "#servicios",
					"name": "Servicios",
					"icon": "mdi-archive"
				},
				{
					"route": "#nosotros",
					"name": "Nosotros",
					"icon": "mdi-checkbox-multiple-marked"
				},
				{
					"route": "#clientes",
					"name": "Clientes",
					"icon": "mdi-magnify-expand"
				},
				{
					"route": "#certificaciones",
					"name": "Certificaciones",
					"icon": "mdi-gauge"
				},
				{
					"route": "#contacto",
					"name": "Contacto",
					"icon": "mdi-contacts"
				},
			],
			items: [
				{
					src: '/imgs/logos/bbva.webp',
				},
				{
					src: '/imgs/logos/cemex.webp',
				},
				{
					src: '/imgs/logos/dhl.webp',
				},
				{
					src: '/imgs/logos/santander.webp',
				},
			],
			campos: [
				{
					"cols": "4",
					"type": "text",
					"label": "Nombre",
				},
				{
					"cols": "4",
					"type": "text",
					"label": "Correo",
				},
			]
		}),
		created: function () {
			var wh = window.screen.availWidth;
			if(wh < 600){
				this.mobile = true;
			}
		},
		mounted: function () {
			var tl = gsap.timeline({defaults:{duration: 1, opacity: 0}});

			this.windowwidth = window.outerWidth;

			tl.from(".lema", {y: 100, stagger: 0.5});
			tl.from("#logo", {y: 100}, "-=0.5");
			tl.from(".hmenu", {x: 100,}, "-=1.5");

			var parallax = gsap.timeline({scrollTrigger: {
				trigger: ".header",
				start: "top center",
				end: "bottom center",
				scrub: true
			}});

			parallax.to(".header", {
				backgroundPosition: "50% 0%",
				ease: "none",
			});

			var serv = gsap.timeline({
				scrollTrigger: {
					trigger: "#servicios",
					start: "top center",
					end: "+=300",
					scrub: true
				},
				defaults: {
					duration: 1,
					opacity: 0
				}
			});

			serv.from(".cardserv", {y: 100, stagger: 0.5});

			var div = gsap.timeline({scrollTrigger: {
				trigger: ".divider",
				start: "top center",
				end: "bottom center",
				scrub: true,
			}});

			div.to(".dv1", {
				backgroundPosition:"0% 50%",
				ease: "none",
			});

			div.to(".dv2", {
				backgroundPosition:"50% 50%",
				ease: "none",
			});

			var nost = gsap.timeline({
				scrollTrigger: {
					trigger: "#nosotros",
					start: "top center",
					end: "+=300",
					scrub: true
				},
				defaults: {
					duration: 1,
					opacity: 0
				}
			});

			nost.from(".nos", {x: 100, stagger: 0.5});

		}
	}
</script>

<style scoped>
	section {
		padding: 60px 0px;
	}

	.fh{
		height: 100vh;
	}
	.bbg{
		background: rgba(0, 0, 0, 0.5);
	}
	.header-menu{
		position: relative;
		top: 0;
		right: 0;
	}
	.header {
		background-image: url('/imgs/architecture.webp');
		background-position: center;
		background-color: #000;
	}
	.header .row {
		margin: 0 !important;
	}
	.header div {
		padding: 0 !important;
	}

	.dv1{
		height: 30vh;
		background-image: url('/imgs/job.webp');
		background-position: bottom;
	}

	.dv2{
		height: 30vh;
		background-image: url('/imgs/man.webp');
		background-position: bottom;
	}

	.video{
		width: 420px;
	}

	#servicios{
		background-color: #DDD;
	}

	@media only screen and (max-width: 360px) {
		.video{
			width: 100%;
		}
		.map{
			height: 450px;
		}
	}

	@media only screen and (max-width: 600px) {
		#logo{
			width: 50%;
		}
	}
</style>
