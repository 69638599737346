<template>
<nav>
	<v-navigation-drawer :app="true" v-model="sidenav" dark temporary color="black">
		<template v-slot:prepend>
			<v-img src="/imgs/logo.webp" width="50%" class="mx-auto my-6">
				<template v-slot:placeholder>
					<v-row class="fill-height ma-0" align="center" justify="center">
						<v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
					</v-row>
				</template>
			</v-img>
		</template>

		<v-divider></v-divider>

		<v-list>
			<v-list-item-group active-class="orange--text text--accent-4">
				<v-list-item v-for="(site, index) in sites" :key="index" :href="site.route">
					<v-list-item-content>
						<v-list-item-title>{{ site.name }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list-item-group>
		</v-list>
	</v-navigation-drawer>

	<v-app-bar app dark :inverted-scroll="!this.mobile" color="orange">
		<v-app-bar-nav-icon @click.stop="sidenav = !sidenav"><v-icon>mdi-dots-vertical</v-icon></v-app-bar-nav-icon>
		<img class="mr-2" src="/imgs/logo.webp" width="32px" />
		<v-toolbar-title class="text-uppercase">

			<span class="font-weight-light">Grupo</span>
			<span>Gavira</span>
		</v-toolbar-title>

		<v-spacer></v-spacer>

		<v-toolbar-items class="hidden-sm-and-down">
			<v-btn v-for="(site, index) in sites" :key="index" :href="site.route" text>
				{{ site.name }}
			</v-btn>
		</v-toolbar-items>
	</v-app-bar>

</nav>
</template>

<script>
export default {
	data() {
		return {
			mobile: false,
			sidenav: false,
			sites: [
				{
					"route": "#home",
					"name": "Home",
					"icon": "mdi-file-download-outline"
				},
				{
					"route": "#servicios",
					"name": "Servicios",
					"icon": "mdi-archive"
				},
				{
					"route": "#nosotros",
					"name": "Nosotros",
					"icon": "mdi-checkbox-multiple-marked"
				},
				{
					"route": "#clientes",
					"name": "Clientes",
					"icon": "mdi-magnify-expand"
				},
				{
					"route": "#contacto",
					"name": "Contacto",
					"icon": "mdi-contacts"
				},
			]
		}
	},
	created: function () {
		var wh = window.screen.availWidth;
		if(wh < 600){
			this.mobile = true;
		}
	},
}
</script>
